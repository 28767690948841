import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { MsalGuard } from '@azure/msal-angular';

import { ServiceCallsComponent } from './service-calls/service-calls.component';

const routes: Routes = [
  {
    path: '',
    component: ServiceCallsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    component: ServiceCallsComponent,
    canActivate: [MsalGuard],
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
