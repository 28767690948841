import { Injectable } from '@angular/core';
import { ApiServiceInterface, SelectOption } from '@gea/digital-ui-lib';
import { AssetApiService } from './asset-api.service';
import { Observable } from 'rxjs';
import { ProductSelection } from '../models/product-selection.model';

@Injectable({
  providedIn: 'root',
})
export class ProductSelectionService implements ApiServiceInterface {
  apiVersion = '1';
  url = '/products/selection';

  constructor(private api: AssetApiService) {}

  public getSelectedProducts(): Observable<ProductSelection> {
    return this.api.get<ProductSelection>(
      `${this.url}?applyOptionalExcludeRules=true`,
      {},
      this.apiVersion,
    );
  }

  public updateSelectedProducts(products: SelectOption[]): void {
    this.api
      .patch(
        this.url,
        {
          registeredProductIds: products.map(
            (product) => product.value as string,
          ),
        },
        {},
        this.apiVersion,
      )
      .subscribe({});
  }

  setUrl(url?: string) {
    this.url = url ?? this.url;
    return this;
  }

  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }
}
