/**
 * Service Call Service
 * Service Call Service
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InfoResponseGit } from './infoResponseGit';
import { InfoResponseBuild } from './infoResponseBuild';
import { InfoResponseDatabaseSchema } from './infoResponseDatabaseSchema';


export interface InfoResponse { 
    build?: InfoResponseBuild;
    databaseSchema?: InfoResponseDatabaseSchema;
    git?: InfoResponseGit;
}

