/**
 * Service Call Service
 * Service Call Service
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Activity } from './activity';


export interface ServiceCallList { 
    entryCount: number;
    pageEntries: Array<Activity>;
}

