/**
 * Service Call Service
 * Service Call Service
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceCategory } from './serviceCategory';
import { ServiceReport } from './serviceReport';
import { ActivityStatusCode } from './activityStatusCode';


export interface Activity { 
    lastModified?: string;
    serviceLogId?: string;
    serviceActivityId?: string;
    requestId?: string;
    title?: string;
    technician?: string;
    plannedStartDate?: string;
    plannedEndDate?: string;
    activityStatusCode?: ActivityStatusCode;
    serviceReports?: Array<ServiceReport>;
    serviceCategory?: ServiceCategory;
    productId?: string;
    productDescription?: string;
    remoteSupportId?: string;
}
export namespace Activity {
}


