import { Injectable } from '@angular/core';
import { ActivitiesApiService, ServiceCallList, ServiceReportApiService } from '../api/v1';
import { FilterTableSettings, SelectOption } from '@gea/digital-ui-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceCallActivitiesService {
  constructor(
    private activitiesApiService: ActivitiesApiService,
    private serviceReportApiService: ServiceReportApiService
  ) {}

  getServiceCallActivities(tableFilter?: FilterTableSettings): Observable<ServiceCallList> {
    const sortBy = this.getSortBy(tableFilter);
    const sortDir = this.getSortDir(tableFilter);
    const filter = this.getFilterParams(tableFilter);

    return this.activitiesApiService.getServiceCalls(
      tableFilter?.page,
      tableFilter?.pageSize,
      tableFilter?.searchValue,
      sortBy,
      sortDir,
      filter
    );
  }

  getServiceCallReport(serviceReportId: string): Observable<Blob> {
    return this.serviceReportApiService.getServiceReport(serviceReportId);
  }

  getSortBy(tableFilter?: FilterTableSettings): string | undefined {
    if (!tableFilter) {
      return undefined;
    }
    const sortByColumn = Object.keys(tableFilter.columns).find((key) => tableFilter.columns[key].sort);
    return sortByColumn ?? undefined;
  }

  getSortDir(tableFilter?: FilterTableSettings): string | undefined {
    if (!tableFilter) {
      return undefined;
    }
    const sortByColumn = Object.keys(tableFilter.columns).find((key) => tableFilter.columns[key].sort);
    return sortByColumn ? tableFilter.columns[sortByColumn].sort : undefined;
  }

  getFilterParams(tableFilter?: FilterTableSettings): string[] | undefined {
    if (!tableFilter || !tableFilter.multiSelectFilter) {
      return undefined;
    }
    const filterParams: string[] = [];
    for (const key in tableFilter.columns) {
      const filter = tableFilter.columns[key].filter ?? [];
      filter.forEach((filterValue) => {
        filterParams.push(`${key}[contains]=${filterValue}`);
      });
    }

    tableFilter.multiSelectFilter.forEach((filter: SelectOption<string>) => {
      filterParams.push('productId[equals]=' + filter.value);
    });

    return filterParams ?? undefined;
  }
}
