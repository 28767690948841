import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Activity, ActivityStatusCode } from '../../../api/v1';
import { StatusComponent } from '../status/status.component';

@Component({
  selector: 'service-call-status-renderer',
  templateUrl: './status-renderer.component.html',
  standalone: true,
  imports: [StatusComponent],
})
export class StatusRendererComponent implements ColumnRendererComponent<Activity> {
  ActivityStatusCode = ActivityStatusCode;
  rowData?: Activity;
}
