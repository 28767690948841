import { Component, Inject } from '@angular/core';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Activity } from '../../api/v1';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../../environments/models/environment.model';

@Component({
  selector: 'service-call-link-renderer',
  templateUrl: './link-renderer.component.html',
  styleUrls: ['./link-renderer.component.scss'],
  standalone: true,
  imports: [],
})
export class LinkRendererComponent implements ColumnRendererComponent<Activity> {
  rowData?: Activity;

  constructor(@Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration) {}

  goToTicket(requestId: string | undefined) {
    const fullSupportTicketUrl = this.environment.advance.redirectURL + '/tickets/' + requestId;
    window.open(fullSupportTicketUrl, '_blank');
  }
}
