/**
 * Service Call Service
 * Service Call Service
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ServiceCategory = 'INSTALLATION' | 'INSTALLATION_AND_COMMISSIONING' | 'TRAINING' | 'SPARE_PARTS' | 'CORRECTIVE_MAINTENANCE' | 'PREVENTATIVE_MAINTENANCE' | 'SERVICE_CONTRACTS' | 'UPGRADES_MODERNIZATION_AND_OPTIMIZATION' | 'IN_HOUSE_REPAIR' | 'ON_SITE_PROJECT' | 'DIGITAL_SOLUTIONS' | 'COMPLAINT' | 'DECOMMISSIONING_AND_RECYCLING_SERVICES' | 'NOT_FOUND';

export const ServiceCategory = {
    Installation: 'INSTALLATION' as ServiceCategory,
    InstallationAndCommissioning: 'INSTALLATION_AND_COMMISSIONING' as ServiceCategory,
    Training: 'TRAINING' as ServiceCategory,
    SpareParts: 'SPARE_PARTS' as ServiceCategory,
    CorrectiveMaintenance: 'CORRECTIVE_MAINTENANCE' as ServiceCategory,
    PreventativeMaintenance: 'PREVENTATIVE_MAINTENANCE' as ServiceCategory,
    ServiceContracts: 'SERVICE_CONTRACTS' as ServiceCategory,
    UpgradesModernizationAndOptimization: 'UPGRADES_MODERNIZATION_AND_OPTIMIZATION' as ServiceCategory,
    InHouseRepair: 'IN_HOUSE_REPAIR' as ServiceCategory,
    OnSiteProject: 'ON_SITE_PROJECT' as ServiceCategory,
    DigitalSolutions: 'DIGITAL_SOLUTIONS' as ServiceCategory,
    Complaint: 'COMPLAINT' as ServiceCategory,
    DecommissioningAndRecyclingServices: 'DECOMMISSIONING_AND_RECYCLING_SERVICES' as ServiceCategory,
    NotFound: 'NOT_FOUND' as ServiceCategory
};

