import { Component, Input } from '@angular/core';
import { IconModule, SharedModule } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { NgIf } from '@angular/common';

@Component({
  selector: 'service-call-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  standalone: true,
  imports: [SharedModule, TranslateModule, IconModule, TooltipModule, NgIf],
})
export class StatusComponent {
  @Input() status = 'default';

  map: Record<string, { label: string; icon: string; progress: number; color: string }> = {
    NOT_FOUND: {
      label: 'ACTIVITY.STATUS.NOT_FOUND',
      icon: '16px_warning-sign',
      progress: 0,
      color: 'geaui-red-40',
    },
    CANCELLED: {
      label: 'ACTIVITY.STATUS.CANCELLED',
      icon: '16px_warning-sign',
      progress: 0,
      color: 'geaui-grey-04',
    },
    DISPATCHING: {
      label: 'ACTIVITY.STATUS.DISPATCHED',
      icon: '16px_refresh-02',
      progress: 20,
      color: 'geaui-active-blue',
    },
    PLANNED: {
      label: 'ACTIVITY.STATUS.PLANNED',
      icon: '16px_refresh-02',
      progress: 40,
      color: 'geaui-active-blue',
    },
    EXECUTION: {
      label: 'ACTIVITY.STATUS.EXECUTION',
      icon: '16px_wrench-tool',
      progress: 60,
      color: 'geaui-primary',
    },
    ADMIN: {
      label: 'ACTIVITY.STATUS.ADMIN',
      icon: '16px_refresh-02',
      progress: 80,
      color: 'geaui-primary',
    },
    CLOSED: {
      label: 'ACTIVITY.STATUS.CLOSED',
      icon: '16px_check',
      progress: 100,
      color: 'geaui-tertiary-green-60',
    },
    default: {
      label: 'ACTIVITY.STATUS.NOT_FOUND',
      icon: '16px_warning-sign',
      progress: 0,
      color: 'geaui-red-40',
    },
  };
}
