<geaui-page-layout [headerTemplate]="header" [contentTemplate]="content" [sidebarTemplate]="sidebar">
  <ng-template #header>
    <geaui-header [noAuth]="noAuth" [headerTitle]="SERVICE_CALL_APP_TITLE | translate" [showBanner]="false"></geaui-header>
  </ng-template>
  <ng-template #content>
    <div class="spinner" *ngIf="(!tokenReady || !userReady) && !loggedOutPageReady">
      <p-progressSpinner animationDuration="2s" strokeWidth="4"> </p-progressSpinner>
    </div>
    <div class="content-body">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
  <ng-template #sidebar>
    @if (!noAuth && tokenReady) {
      <geaui-sidebar-v2
        [navigationItems]="navItems"
        [slimView]="true"
        [bottomNavigation]="botItems"
        [devisionIconPath]="environment.appContentStorageURL + 'core/service-call/icons/24px_service-call-app.svg'"
        devisionIconBackgroundColor="var(--geaui-energetic-pink-130)"
        [devisionName]="SERVICE_CALL_APP_TITLE | translate">
      </geaui-sidebar-v2>
    }
  </ng-template>
</geaui-page-layout>
