<geaui-page-content-layout>
  <ng-container page-content>
    <div class="service-calls-container geaui-grid geaui-sidebar-grid geaui-scrollable-table">
      <div
        class="align-center gea-font-heading-24 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-6 geaui-col-tablet-lg-4 geaui-col-tablet-sm-4 geaui-col-phone-lg-4 geaui-col-phone-sm-2"
        data-cy="title">
        {{ 'SERVICE-CALLS-LIST.TITLE' | translate }}
      </div>
      <geaui-multi-select-v2
        class="align-center geaui-col-desktop-xl-3 geaui-col-desktop-md-3 geaui-col-tablet-lg-4 geaui-col-tablet-sm-4 geaui-col-phone-lg-4 geaui-col-phone-sm-2"
        [selected]="selectedProducts"
        [options]="products"
        [hideSearchbar]="true"
        [enableTooltipOptionDescription]="true"
        [label]="'SERVICE-CALL-LIST.EQUIPMENT' | translate"
        (selectChanged)="onProductsChanged($event)"
        (clearButtonClick)="onClearSelections()"
        data-cy="products">
      </geaui-multi-select-v2>
      <geaui-search-bar-v2
        class="align-center width-full geaui-col-desktop-xl-3 geaui-col-desktop-md-3 geaui-col-tablet-lg-4 geaui-col-tablet-sm-4 geaui-col-phone-lg-4 geaui-col-phone-sm-2"
        [placeholder]="'SERVICE-CALL-LIST.SEARCH' | translate"
        [hasSuggestions]="false"
        [initialValue]="searchInput"
        (valueChanged)="searchInput = $event"
        (searchButtonClicked)="onSearchServiceCalls()"
        (keydown)="onSearchTicketsByClickEnter($event)"
        (clearEvent)="clearSearch()"
        data-cy="search-bar">
      </geaui-search-bar-v2>
      <geaui-table-v2
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
        [tableId]="TABLE_ID"
        [columnDefinitions]="serviceCallColumns"
        [data]="serviceCallActivities"
        [loading]="loading"
        [defaultSettings]="defaultTableSettings"
        [noDataText]="('X.NO-RESULT' | translate)"
        [sortable]="true"
        [totalRecords]="entryCount">
      </geaui-table-v2>
    </div>
  </ng-container>
</geaui-page-content-layout>
