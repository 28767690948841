<div class="status-container">
  <div class="label">
    <geaui-icon-v2 [file]="map[status].icon" color="black"></geaui-icon-v2>
    <div class="label-container">
      <span
        class="status-label"
        [pTooltip]="map[status].label | translate"
        geauiTooltipOnEllipsisV2
      >
        {{ map[status].label | translate }}
      </span>
    </div>
  </div>
  <div class="progress">
    <div
      [style.width]="map[status].progress + '%'"
      [style.backgroundColor]="'var(--' + map[status]?.color + ')'"
    ></div>
    <div
      class="empty"
      [style.width]="'calc(100% - ' + map[status]?.progress + '%)'"
    ></div>
  </div>
</div>
