/**
 * Service Call Service
 * Service Call Service
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActivityStatusCode = 'NOT_FOUND' | 'CANCELLED' | 'DISPATCHING' | 'PLANNED' | 'EXECUTION' | 'ADMIN' | 'CLOSED';

export const ActivityStatusCode = {
    NotFound: 'NOT_FOUND' as ActivityStatusCode,
    Cancelled: 'CANCELLED' as ActivityStatusCode,
    Dispatching: 'DISPATCHING' as ActivityStatusCode,
    Planned: 'PLANNED' as ActivityStatusCode,
    Execution: 'EXECUTION' as ActivityStatusCode,
    Admin: 'ADMIN' as ActivityStatusCode,
    Closed: 'CLOSED' as ActivityStatusCode
};

