import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  appendVisibilitySettings,
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  ErrorHandlerV2Service,
  FilterTableSettings,
  SelectOption,
  SortDirection,
  TableServiceV2,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { ProductSelectionService } from '../services/product-selection.service';
import { catchError, concatMap, of, take, tap } from 'rxjs';
import { ProductSelection } from '../models/product-selection.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ServiceCallActivitiesService } from '../services/service-call-activities.service';
import { Activity, ServiceCallList } from '../api/v1';
import { StatusRendererComponent } from './activity-status/status-renderer/status-renderer.component';
import { LinkRendererComponent } from './link-renderer/link-renderer.component';
import { ServiceReportRendererComponent } from './serivce-report-renderer/service-report-renderer.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'service-call-list',
  templateUrl: './service-calls.component.html',
  styleUrls: ['./service-calls.component.scss'],
})
export class ServiceCallsComponent implements OnInit {
  protected readonly TABLE_ID = 'service-calls-list';
  protected readonly TRANSLATION_PREFIX = 'SERVICE_CALL';

  products: SelectOption[] = [];
  selectedProducts: SelectOption[] = [];
  loading = true;
  serviceCallActivities: Activity[] = [];
  filterTableSettings?: FilterTableSettings;
  searchInput = '';
  searchQueryParam = '';
  entryCount = 0;

  defaultTableSettings = {
    columns: {
      lastModified: {
        sort: SortDirection.DESCENDING,
      },
    },
  };

  serviceCallColumns: ColumnDefinition[] = appendVisibilitySettings([
    {
      key: 'lastModified',
      displayName: 'SERVICE-CALL-LIST.LAST-MODIFIED',
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'short',
        },
      },
      width: 200,
    },
    {
      key: 'serviceCategory',
      displayName: 'SERVICE-CALL-LIST.SERVICE-CATEGORY',
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          prefix: 'SERVICE-CALL-LIST.SERVICE-CATEGORY.',
        },
      },
      width: 250,
    },
    {
      key: 'serviceActivityId',
      displayName: 'SERVICE-CALL-LIST.ID',
      width: 150,
    },
    {
      key: 'title',
      displayName: 'SERVICE-CALL-LIST.TITLE',
      width: 250,
    },
    {
      key: 'productDescription',
      displayName: 'SERVICE-CALL-LIST.EQUIPMENT',
      width: 250,
    },
    {
      key: 'technician',
      displayName: 'SERVICE-CALL-LIST.TECHNICIAN',
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      },
      width: 200,
    },
    {
      key: 'plannedStartDate',
      displayName: 'SERVICE-CALL-LIST.PLANNED-START-DATE',
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'short',
        },
      },
      width: 200,
    },
    {
      key: 'plannedEndDate',
      displayName: 'SERVICE-CALL-LIST.PLANNED-END-DATE',
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'short',
        },
      },
      width: 200,
    },
    {
      key: 'activityStatusCode',
      displayName: 'SERVICE-CALL-LIST.EXECUTION-STATUS',
      renderer: {
        component: StatusRendererComponent as ColumnRendererComponent<unknown>,
      },
      width: 350,
    },
    {
      key: 'requestId',
      displayName: 'SERVICE-CALL-LIST.REQUEST-NUMBER',
      renderer: {
        component: LinkRendererComponent as ColumnRendererComponent<unknown>,
      },
      width: 150,
    },
    {
      key: 'remoteSupportId',
      displayName: 'SERVICE-CALL-LIST.REMOTE-SUPPORT-ID',
      width: 250,
    },
    {
      displayName: 'SERVICE-CALL-LIST.SERVICE-REPORT',
      key: 'serviceReport',
      sortable: false,
      renderer: {
        component: ServiceReportRendererComponent as ColumnRendererComponent<unknown>,
      },
    },
  ]);

  constructor(
    private tableService: TableServiceV2,
    private errorHandlerService: ErrorHandlerV2Service,
    private productSelectionService: ProductSelectionService,
    private serviceCallActivitiesService: ServiceCallActivitiesService,
    private route: ActivatedRoute,
    private destroyed: DestroyRef
  ) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyed)).subscribe((params) => {
      this.searchQueryParam = params.get('search') ?? '';
      console.log(this.searchQueryParam);
      this.loadFilterAndServiceLogs();
    });
  }

  loadFilterAndServiceLogs() {
    this.productSelectionService
      .getSelectedProducts()
      .pipe(
        take(1),
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, this.TRANSLATION_PREFIX);
          return of({ organizations: [] } as ProductSelection);
        }),
        tap((productSelection: ProductSelection) => {
          this.updateProductSelections(productSelection);
          this.updateSelectedProductsFilter(this.selectedProducts);
        }),
        concatMap(() => this.tableService.getFilterTableSettings(this.TABLE_ID)),
        takeUntilDestroyed(this.destroyed)
      )
      .subscribe({
        next: (tableSettings) => {
          this.filterTableSettings = tableSettings;
          this.searchInput = tableSettings.searchValue ?? this.searchQueryParam;
          tableSettings.searchValue = this.searchInput;
          this.reloadServiceCallData(tableSettings);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, this.TRANSLATION_PREFIX);
        },
      });
  }

  reloadServiceCallData(tableFilter?: FilterTableSettings) {
    this.loading = true;
    this.serviceCallActivitiesService
      .getServiceCallActivities(tableFilter)
      .pipe(take(1))
      .subscribe({
        next: this.receiveServiceCallData.bind(this),
        error: (error: ApiErrorResponse) => {
          this.loading = false;
          this.errorHandlerService.handleError(error);
        },
      });
  }

  receiveServiceCallData(serviceCalls: ServiceCallList) {
    this.serviceCallActivities = serviceCalls.pageEntries.map((entry) => {
      return {
        ...entry,
        technician: entry.technician ?? 'SERVICE-CALL-LIST.NO-TECHNICIAN',
      };
    });
    this.entryCount = serviceCalls.entryCount;
    this.loading = false;
  }

  updateProductSelections(productSelection: ProductSelection) {
    const tmpProducts: SelectOption[] = [];
    const tmpSelectedProducts: SelectOption[] = [];

    productSelection.organizations.forEach((organization) => {
      organization.products.forEach((product) => {
        tmpProducts.push({ value: product.id, name: product.displayName });
        if (product.selected) {
          tmpSelectedProducts.push({
            value: product.id,
            name: product.displayName,
          });
        }
      });
    });

    this.products = tmpProducts;
    this.selectedProducts = tmpSelectedProducts;
  }

  updateSelectedProductsFilter(selectedProducts: SelectOption[]) {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      multiSelectFilter: selectedProducts,
      page: 0,
    });
  }

  onProductsChanged(selectedOptions: SelectOption[]) {
    this.productSelectionService.updateSelectedProducts(selectedOptions);
    this.updateSelectedProductsFilter(selectedOptions);
  }

  onClearSelections() {
    this.productSelectionService.updateSelectedProducts([]);
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      multiSelectFilter: [],
    });
  }

  onSearchServiceCalls() {
    if (!this.searchInput) {
      this.clearSearch();
      return;
    }
    this.updateTableSettings();
  }

  updateTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: this.searchInput !== '',
      searchValue: this.searchInput,
      page: 0,
    });
  }

  clearTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: false,
      searchValue: '',
    });
  }

  clearSearch() {
    this.searchInput = '';
    this.clearTableSettings();
  }

  onSearchTicketsByClickEnter(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;
    this.onSearchServiceCalls();
  }
}
