import { Component, DestroyRef } from '@angular/core';
import { ApiErrorResponse, ColumnRendererComponent, ErrorHandlerV2Service, IconModule } from '@gea/digital-ui-lib';
import { Activity, ServiceReport } from '../../api/v1';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceCallActivitiesService } from '../../services/service-call-activities.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'service-call-service-report-renderer',
  templateUrl: './service-report-renderer.component.html',
  styleUrls: ['./service-report-renderer.component.scss'],
  standalone: true,
  imports: [IconModule, NgIf, TranslateModule, NgForOf],
})
export class ServiceReportRendererComponent implements ColumnRendererComponent<Activity> {
  readonly SERVICE_REPORT_ICON = '24px_pdf';
  rowData?: Activity;

  constructor(
    private serviceCallActivitiesService: ServiceCallActivitiesService,
    private errorHandlerService: ErrorHandlerV2Service,
    private destroyed: DestroyRef
  ) {}

  downloadFile(serviceReport: ServiceReport) {
    if (!serviceReport?.id) {
      return;
    }

    this.serviceCallActivitiesService
      .getServiceCallReport(serviceReport.id)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe({
        next: (file: Blob) => {
          this.downloadFromBrowser(file, serviceReport.name ?? '');
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SERVICE_CALL');
        },
      });
  }

  downloadFromBrowser(file: Blob, fileName: string) {
    const anchorElement = document.createElement('a');
    const objectUrl = URL.createObjectURL(file);
    anchorElement.href = objectUrl;
    anchorElement.download = fileName;
    anchorElement.click();
    URL.revokeObjectURL(objectUrl);
  }
}
